import { Card, CardContent } from '@material-ui/core'
import MaterialTable from 'material-table'
import React from 'react'

import ServiceBusMessageDetails from 'components/ServiceBusMessageDetails'
import { AzureProxiesContext } from 'contexts/AzureProxiesContext'
import { withSnackbar, WithSnackbarProps } from 'notistack'
import { IServiceBusMessage } from 'proxies/AzureServiceBusProxy'
import PeekMessagesModal from './PeekMessagesModal'

export interface IProps extends WithSnackbarProps {
  serviceBusNamespaceName: string
  topicName: string
  topicSubscriptionName: string
}

export interface IState {
  serviceBusMessages: IServiceBusMessage[]
}

export class ServiceBusTopicSubscriptionDetailPanel extends React.Component<IProps, IState> {
  public static contextType = AzureProxiesContext
  public context!: React.ContextType<typeof AzureProxiesContext>

  constructor(props) {
    super(props)

    this.state = {
      serviceBusMessages: []
    }
  }

  private _peekMessages = async (amount: number, deadLetter: boolean) => {
    const success = this.context.sbProxy.peekLockMessages(
      this.props.serviceBusNamespaceName,
      this.props.topicName,
      this.props.topicSubscriptionName,
      amount,
      deadLetter,
      async (msg) => {
        if (msg === null) {
          this.props.enqueueSnackbar(
            `No more messages could be found in ${this.props.topicName}.${this.props.topicSubscriptionName}`,
            { variant: 'warning' }
          )
        } else {
          const newMessages = [...this.state.serviceBusMessages]
          newMessages.push(msg)
          this.setState({ serviceBusMessages: newMessages })
        }
      }
    )

    if (!success) {
      this.props.enqueueSnackbar(
        `An error was encountered trying to peek messages in ${this.props.topicName}.${this.props.topicSubscriptionName}`,
        { variant: 'error' }
      )
    }
  }

  private _renderDetailPanel = (serviceBusMessage: IServiceBusMessage) => {
    return <ServiceBusMessageDetails serviceBusMessage={serviceBusMessage} {...this.props} />
  }

  public render() {
    return (
      <Card>
        <CardContent>
          <PeekMessagesModal peek={this._peekMessages} />

          <MaterialTable<IServiceBusMessage>
            columns={[
              {
                title: 'Enqueued Sequence Number',
                field: 'brokerProperties.EnqueuedSequenceNumber' as keyof IServiceBusMessage
              },
              { title: 'State', field: 'brokerProperties.State' as keyof IServiceBusMessage },
              { title: 'Enqueued Time UTC', field: 'brokerProperties.EnqueuedTimeUtc' as keyof IServiceBusMessage },
              { title: 'Dead Letter', field: 'isDeadLetter' as keyof IServiceBusMessage, type: 'boolean' },
              { title: 'Signature is Valid', field: 'hasValidSignature' as keyof IServiceBusMessage, type: 'boolean' }
            ]}
            data={this.state.serviceBusMessages}
            title="Messages"
            options={{ paging: false }}
            detailPanel={this._renderDetailPanel}
          />
        </CardContent>
      </Card>
    )
  }
}

export default withSnackbar(ServiceBusTopicSubscriptionDetailPanel)
