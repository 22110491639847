import { authContext } from 'configs/AdalConfig'
import { runWithAdal } from 'react-adal'

// tslint:disable-next-line: no-var-requires
require('typeface-roboto')

const DO_NOT_LOGIN = false

runWithAdal(
  authContext,
  () => {
    // eslint-disable-next-line
    require('./indexApp.tsx')
  },
  DO_NOT_LOGIN
)
