import Axios, { AxiosInstance, AxiosResponse } from 'axios'
import { getToken } from 'configs/AdalConfig'

const BASE_URL = 'https://management.usgovcloudapi.net'

export interface ISubscription {
  subscriptionId: string
  displayName: string
}

export interface IResourceGroup {
  id: string
  name: string
}

export interface IServiceBusNamespace {
  id: string
  name: string
}

export interface IServiceBusTopic {
  id: string
  name: string
  properties: {
    subscriptionCount: number
  }
  aggregatedDeadLetterMessageCount?: number
  aggregatedActiveMessageCount?: number
}

export interface IServiceBusTopicSubscription {
  id: string
  name: string
  location: string
  properties: {
    countDetails: {
      activeMessageCount: number
      deadLetterMessageCount: number
      scheduledMessageCount: number
      transferDeadLetterMessageCount: number
      transferMessageCount: number
    }
  }
}

export class AzureManagementProxy {
  private _axios: AxiosInstance

  // eslint-disable-next-line
  private constructor() {}

  public static create = async (): Promise<AzureManagementProxy> => {
    const proxy = new AzureManagementProxy()
    await proxy._initialize()
    return proxy
  }

  private _initialize = async () => {
    console.log("Get Token")

    const token = await getToken(BASE_URL)

    console.log("Got Token", token)

    this._axios = Axios.create({
      baseURL: BASE_URL,
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      validateStatus: () => true
    })
  }

  private _handleAzmApiResult = (response: AxiosResponse<any>): any => {
    if (response.status >= 200 && response.status < 300 && response.data) {
      return response.data.value
    }
    return null
  }

  public listSubscriptions = async (): Promise<ISubscription[]> => {
    const listSubscriptionsPath = 'subscriptions?api-version=2016-06-01'

    const result = await this._axios.get(listSubscriptionsPath)
    return this._handleAzmApiResult(result)
  }

  public listResourceGroups = async (subscriptionId: string): Promise<IResourceGroup[]> => {
    const listResourceGroupsUrl = 'subscriptions/{subscriptionId}/resourcegroups?api-version=2018-05-01'

    const result = await this._axios.get(listResourceGroupsUrl.replace('{subscriptionId}', subscriptionId))

    return this._handleAzmApiResult(result)
  }

  public listServiceBusNamespacesInResourceGroup = async (
    subscriptionId: string,
    resourceGroupName: string
  ): Promise<IServiceBusNamespace[]> => {
    const listServiceBusNamespacesByResourceGroupPath =
      'subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Microsoft.ServiceBus/namespaces?api-version=2017-04-01'

    const result = await this._axios.get(
      listServiceBusNamespacesByResourceGroupPath
        .replace('{subscriptionId}', subscriptionId)
        .replace('{resourceGroupName}', resourceGroupName)
    )

    return this._handleAzmApiResult(result)
  }

  public listServiceBusNamespaces = async (subscriptionId: string): Promise<IServiceBusNamespace[]> => {
    const listServiceBusNamespacesPath =
      'subscriptions/{subscriptionId}/providers/Microsoft.ServiceBus/namespaces?api-version=2017-04-01'

    const result = await this._axios.get(listServiceBusNamespacesPath.replace('{subscriptionId}', subscriptionId))

    return this._handleAzmApiResult(result)
  }

  public listServiceBusTopics = async (
    subscriptionId: string,
    resourceGroupName: string,
    serviceBusNamespaceName: string
  ): Promise<IServiceBusTopic[]> => {
    const listServiceBusNamespacesByResourceGroupPath =
      'subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Microsoft.ServiceBus/namespaces/{namespaceName}/topics?api-version=2017-04-01'

    const result = await this._axios.get(
      listServiceBusNamespacesByResourceGroupPath
        .replace('{subscriptionId}', subscriptionId)
        .replace('{resourceGroupName}', resourceGroupName)
        .replace('{namespaceName}', serviceBusNamespaceName)
    )

    let nextLink = result.data.nextLink

    while (nextLink) {
      const nextResult = await this._axios.get(nextLink.split(BASE_URL).pop())
      result.data.value.push(...nextResult.data.value)
      nextLink = nextResult.data.nextLink
    }

    return this._handleAzmApiResult(result)
  }

  public listServiceBusSubscriptionsByTopic = async (
    subscriptionId: string,
    resourceGroupName: string,
    serviceBusNamespaceName: string,
    topicName: string
  ): Promise<IServiceBusTopicSubscription[]> => {
    const listServiceBusSubscriptionsByTopicPath =
      'subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Microsoft.ServiceBus/namespaces/{namespaceName}/topics/{topicName}/subscriptions?api-version=2017-04-01'

    const result = await this._axios.get(
      listServiceBusSubscriptionsByTopicPath
        .replace('{subscriptionId}', subscriptionId)
        .replace('{resourceGroupName}', resourceGroupName)
        .replace('{namespaceName}', serviceBusNamespaceName)
        .replace('{topicName}', topicName)
    )

    return this._handleAzmApiResult(result)
  }

  public deleteServiceBusSubscription = async (
    subscriptionId: string,
    resourceGroupName: string,
    serviceBusNamespaceName: string,
    topicName: string,
    subscriptionName: string
  ): Promise<IServiceBusTopicSubscription[]> => {
    const deleteServiceBusSubscriptionPath =
      'subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Microsoft.ServiceBus/namespaces/{namespaceName}/topics/{topicName}/subscriptions/{subscriptionName}?api-version=2017-04-01'

    const result = await this._axios.delete(
      deleteServiceBusSubscriptionPath
        .replace('{subscriptionId}', subscriptionId)
        .replace('{resourceGroupName}', resourceGroupName)
        .replace('{namespaceName}', serviceBusNamespaceName)
        .replace('{topicName}', topicName)
        .replace('{subscriptionName}', subscriptionName)
    )

    return this._handleAzmApiResult(result)
  }
}
