import { withSnackbar, WithSnackbarProps } from 'notistack'
import React from 'react'

class SnackbarErrorHandler extends React.Component<WithSnackbarProps> {
  public componentDidCatch() {
    this.props.enqueueSnackbar('An error was encountered. Refreshing the page may be necessary.', {
      variant: 'error'
    })
  }

  public render() {
    return this.props.children
  }
}

export default withSnackbar(SnackbarErrorHandler)
