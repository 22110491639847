import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Slide,
  Toolbar,
  Typography
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import DeleteIcon from '@material-ui/icons/Delete'
import ReplayIcon from '@material-ui/icons/Replay'
import MaterialTable from 'material-table'
import { withSnackbar, WithSnackbarProps } from 'notistack'
import React from 'react'

import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText'
import ServiceBusTopicSubscriptionDetailPanel from 'components/ServiceBusTopicSubscriptionDetailPanel'
import { AzureProxiesContext } from 'contexts/AzureProxiesContext'
import { IServiceBusTopicSubscription } from 'proxies/AzureManagementProxy'
import { IServiceBusMessage } from 'proxies/AzureServiceBusProxy'

export interface IProps extends WithSnackbarProps {
  subscriptionId: string
  resourceGroupName: string
  serviceBusNamespaceName: string
  topicName: string
}

export interface IReEnqueueBatchStatus {
  reEnqueuing: boolean
  progressPercent?: number
}

export interface IState {
  open: boolean
  topicSubscriptions: Map<string, IServiceBusTopicSubscription>
  serviceBusMessages: Map<string, Map<string, IServiceBusMessage>>
  amount: number
  saapKey: string
  reEnqueueBatchStatuses: Map<string, IReEnqueueBatchStatus>
  isPickingReenqueueAmount: boolean
  subToReenqueue: IServiceBusTopicSubscription
  isDeletingSubscription: boolean
  isDeletingSubscriptionName: string
  isDeletingActiveMessages: boolean
  isDeletingDeadLetterMessages: boolean
  deleteActiveMessagesStarted: boolean
  deleteDLMessagesStarted: boolean
  subIdToPurgeMessages: string
  subToPurgeMessages: IServiceBusTopicSubscription
}

function Transition(props) {
  return <Slide direction="up" {...props} />
}

class ServiceBusTopicDialog extends React.Component<IProps, IState> {
  public static contextType = AzureProxiesContext
  public context!: React.ContextType<typeof AzureProxiesContext>

  constructor(props) {
    super(props)

    this.state = {
      open: false,
      topicSubscriptions: new Map<string, IServiceBusTopicSubscription>(),
      serviceBusMessages: new Map<string, Map<string, IServiceBusMessage>>(),
      amount: 1,
      saapKey: '',
      reEnqueueBatchStatuses: new Map<string, IReEnqueueBatchStatus>(),
      isPickingReenqueueAmount: false,
      subToReenqueue: null,
      isDeletingSubscription: false,
      isDeletingSubscriptionName: null,
      subToPurgeMessages: null,
      isDeletingActiveMessages: false,
      isDeletingDeadLetterMessages: false,
      deleteActiveMessagesStarted: false,
      deleteDLMessagesStarted: false,
      subIdToPurgeMessages: ''
    }
  }

  private _handleOpen = async () => {
    this.setState({ open: true })
    await this._listTopicSubscriptions()
  }

  private _handleClose = () => {
    this.setState({ open: false })
  }

  private _handleReenqueueAmountDialogClose = async () => {
    this.setState({ isPickingReenqueueAmount: false, subToReenqueue: null })
  }

  private _handleDeleteSubscriptionDialogClose = async () => {
    this.setState({ isDeletingSubscription: false, isDeletingSubscriptionName: null })
  }

  private _handleReenqueueAmountDialogOpen = (sub: IServiceBusTopicSubscription) => async () => {
    this.setState({ isPickingReenqueueAmount: true, subToReenqueue: sub })
  }

  private _handleDeletingActiveMessagesDialogOpen = (sub: IServiceBusTopicSubscription) => async () => {
    this.setState({ isDeletingActiveMessages: true, subToPurgeMessages: sub })
  }

  private _handleDeletingDeadLetterMessagesDialogOpen = (sub: IServiceBusTopicSubscription) => async () => {
    this.setState({ isDeletingDeadLetterMessages: true, subToPurgeMessages: sub })
  }

  private _handleDeletingMessagesDialogClose = async () => {
    this.setState({ isDeletingActiveMessages: false, isDeletingDeadLetterMessages: false, subToPurgeMessages: null })
  }

  private _listTopicSubscriptions = async () => {
    const subscriptions: IServiceBusTopicSubscription[] = await this.context.azmProxy.listServiceBusSubscriptionsByTopic(
      this.props.subscriptionId,
      this.props.resourceGroupName,
      this.props.serviceBusNamespaceName,
      this.props.topicName
    )

    if (subscriptions === null) {
      this.props.enqueueSnackbar('Unable to retrieve available Service Bus Topic Subscriptions.', { variant: 'error' })
    } else if (!subscriptions || !subscriptions.length) {
      this.props.enqueueSnackbar(
        `No Service Bus Topic Subscriptions could be found for Service Bus Topic: ${this.props.topicName}.`,
        { variant: 'warning' }
      )
    } else {
      this.setState({
        topicSubscriptions: subscriptions.reduce(
          (prev: Map<string, IServiceBusTopicSubscription>, x: IServiceBusTopicSubscription) => prev.set(x.name, x),
          new Map<string, IServiceBusTopicSubscription>()
        )
      })
    }
  }

  private _renderTopicSubscriptionDetailPanel = (topicSubscription: IServiceBusTopicSubscription) => {
    return (
      <ServiceBusTopicSubscriptionDetailPanel
        serviceBusNamespaceName={this.props.serviceBusNamespaceName}
        topicName={this.props.topicName}
        topicSubscriptionName={topicSubscription.name}
      />
    )
  }

  private _setBatchStatus(topicSubscriptionName: string, reEnqueuing: boolean, progressPercent: number) {
    const currentStatus = this.state.reEnqueueBatchStatuses.get(topicSubscriptionName)
    if (
      currentStatus &&
      currentStatus.reEnqueuing === reEnqueuing &&
      currentStatus.progressPercent >= progressPercent
    ) {
      return
    }

    console.log('progressPercent: ', progressPercent)
    const newState = { ...this.state }

    newState.reEnqueueBatchStatuses.set(topicSubscriptionName, { reEnqueuing, progressPercent })
    this.setState(newState)
  }

  // TODO: Run in the background
  private _reEnqueueDeadLetters = (amount: number) => async () => {
    const topicSubscription = { ...this.state.subToReenqueue }
    await this._handleReenqueueAmountDialogClose()

    this._setBatchStatus(topicSubscription.name, true, 0)

    let messageCount = topicSubscription.properties.countDetails.deadLetterMessageCount
    messageCount = Math.min(messageCount, amount)
    let messageReceivedCount = 0
    const success = await this.context.sbProxy.peekLockMessages(
      this.props.serviceBusNamespaceName,
      this.props.topicName,
      topicSubscription.name,
      messageCount,
      true,
      async (message) => {
        if (message == null) {
          this.props.enqueueSnackbar(
            `No more messages could be found in the Topic Subscription "${topicSubscription.name}" dead letter queue.`,
            { variant: 'warning' }
          )
        } else {
          messageReceivedCount++

          const sendSuccess = await this.context.sbProxy.sendMessage(message, this.props.topicName)
          const cleanupSuccess = await this.context.sbProxy.deleteMessage(
            message,
            this.props.serviceBusNamespaceName,
            this.props.topicName,
            topicSubscription.name
          )

          if (!sendSuccess || !cleanupSuccess) {
            this.props.enqueueSnackbar(
              `At least one of the messages in Topic Subscription "${topicSubscription.name}" dead letter queue could ne be re-enqueued.`,
              { variant: 'warning' }
            )
          }

          const progress = Math.ceil((messageReceivedCount / messageCount) * 100)
          this._setBatchStatus(topicSubscription.name, true, progress)
        }
      }
    )

    this._setBatchStatus(topicSubscription.name, false, 0)

    if (!success) {
      this.props.enqueueSnackbar(
        `An error occured while re-enqueuing messages from the Topic Subscription "${topicSubscription.name}" dead letter queue.`,
        { variant: 'error' }
      )
    }
  }

  private _startDeletingSub = (sub: IServiceBusTopicSubscription) => async () => {
    this.setState({ isDeletingSubscription: true, isDeletingSubscriptionName: sub.name })
  }

  private _handleDeleteMessages = async () => {
    const topicSubscription = { ...this.state.subToPurgeMessages }
    const isDeadLetter = this.state.isDeletingDeadLetterMessages

    this._handleDeletingMessagesDialogClose()

    this.setState({
      deleteActiveMessagesStarted: !isDeadLetter,
      deleteDLMessagesStarted: isDeadLetter,
      subIdToPurgeMessages: topicSubscription.id
    })

    const messageCount = isDeadLetter
      ? topicSubscription.properties.countDetails.deadLetterMessageCount
      : topicSubscription.properties.countDetails.activeMessageCount

    const isSuccessful = await this.context.sbProxy.deleteMessages(
      this.props.serviceBusNamespaceName,
      this.props.topicName,
      topicSubscription.name,
      messageCount,
      isDeadLetter
    )

    if (isSuccessful) {
      await this._listTopicSubscriptions()
    }

    this.setState({ deleteActiveMessagesStarted: false, deleteDLMessagesStarted: false, subIdToPurgeMessages: '' })
  }

  private _deleteSubscription = async () => {
    await this.context.azmProxy.deleteServiceBusSubscription(
      this.props.subscriptionId,
      this.props.resourceGroupName,
      this.props.serviceBusNamespaceName,
      this.props.topicName,
      this.state.isDeletingSubscriptionName
    )
    this.setState({ isDeletingSubscription: false, isDeletingSubscriptionName: null })
  }

  private _renderActions = (topicSubscription: IServiceBusTopicSubscription) => {
    const reEnqueueBatchStatus = this.state.reEnqueueBatchStatuses.get(topicSubscription.name)
    if (reEnqueueBatchStatus && reEnqueueBatchStatus.reEnqueuing) {
      return <LinearProgress variant="determinate" value={reEnqueueBatchStatus.progressPercent} />
    } else {
      return (
        <>
          <Button
            hidden={topicSubscription.properties.countDetails.deadLetterMessageCount <= 0}
            onClick={this._handleReenqueueAmountDialogOpen(topicSubscription)}
          >
            <ReplayIcon />
          </Button>

          <Button onClick={this._startDeletingSub(topicSubscription)}>
            <DeleteIcon />
          </Button>
        </>
      )
    }
  }

  private _renderDeleteMessage = (rowData: IServiceBusTopicSubscription, isDeadLetter: boolean) => {
    const messageCount = isDeadLetter
      ? rowData.properties.countDetails.deadLetterMessageCount
      : rowData.properties.countDetails.activeMessageCount
    if (
      rowData.id === this.state.subIdToPurgeMessages &&
      ((isDeadLetter && this.state.deleteDLMessagesStarted) ||
        (!isDeadLetter && this.state.deleteActiveMessagesStarted))
    ) {
      return (
        <span style={{ width: '50%', padding: '0px 0px 0px 20px' }}>
          <CircularProgress size={16} disableShrink={true} />
        </span>
      )
    } else {
      return (
        <span style={{ width: '50%' }}>
          <Button
            color="secondary"
            onClick={
              isDeadLetter
                ? this._handleDeletingDeadLetterMessagesDialogOpen(rowData)
                : this._handleDeletingActiveMessagesDialogOpen(rowData)
            }
            disabled={messageCount <= 0}
          >
            {messageCount <= 0 ? <ClearIcon /> : <ClearIcon color="error" />}
          </Button>
        </span>
      )
    }
  }

  public render() {
    return (
      <>
        <Dialog onClose={this._handleReenqueueAmountDialogClose} open={this.state.isPickingReenqueueAmount}>
          <DialogTitle id="simple-dialog-title">How many messages ?</DialogTitle>
          <div>
            <List>
              {[1, 10, 25, 50, 100, 500, 1000, 5000, 10000].map((amount) => {
                return (
                  <ListItem
                    button
                    onClick={this._reEnqueueDeadLetters(amount)}
                    key={amount}
                    style={{ textAlign: 'center' }}
                  >
                    <ListItemText primary={amount} />
                  </ListItem>
                )
              })}
            </List>
          </div>
        </Dialog>

        <Dialog
          open={this.state.isDeletingSubscription}
          onClose={this._handleDeleteSubscriptionDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Sure you want to delete a sub?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete the subscription {this.state.isDeletingSubscriptionName}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this._handleDeleteSubscriptionDialogClose} color="primary">
              No
            </Button>
            <Button onClick={this._deleteSubscription} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.isDeletingActiveMessages}
          onClose={this._handleDeletingMessagesDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete all Active messages of the subscription
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this._handleDeletingMessagesDialogClose} color="primary">
              No
            </Button>
            <Button onClick={this._handleDeleteMessages} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.isDeletingDeadLetterMessages}
          onClose={this._handleDeletingMessagesDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Confirmation?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete all Dead Letter messages of the subscription
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this._handleDeletingMessagesDialogClose} color="primary">
              No
            </Button>
            <Button onClick={this._handleDeleteMessages} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <Button onClick={this._handleOpen}>Peek Messages</Button>
        <Dialog fullScreen open={this.state.open} onClose={this._handleClose} TransitionComponent={Transition}>
          <AppBar style={{ position: 'relative' }}>
            <Toolbar>
              <Typography variant="h6" color="inherit" style={{ flex: 'auto' }}>
                Peek Topic Messages - {this.props.topicName}
              </Typography>
              <Button color="inherit" onClick={this._handleClose}>
                close
              </Button>
            </Toolbar>
          </AppBar>

          <div style={{ margin: '15px' }}>
            <MaterialTable<IServiceBusTopicSubscription>
              columns={[
                { title: 'Name', field: 'name' as keyof IServiceBusTopicSubscription },
                {
                  title: 'Dead Letter Message Count',
                  render: (rowData) => (
                    <div>
                      <span style={{ width: '50%' }}>{rowData.properties.countDetails.deadLetterMessageCount}</span>
                      {this._renderDeleteMessage(rowData, true)}
                    </div>
                  )
                },
                {
                  title: 'Active Message Count',
                  render: (rowData) => (
                    <div>
                      <span style={{ width: '50%' }}>{rowData.properties.countDetails.activeMessageCount}</span>
                      {this._renderDeleteMessage(rowData, false)}
                    </div>
                  )
                },
                {
                  title: 'Actions',
                  render: this._renderActions
                }
              ]}
              data={Array.from(this.state.topicSubscriptions.values())}
              title="Subscriptions"
              options={{ paging: false }}
              detailPanel={this._renderTopicSubscriptionDetailPanel}
            />
          </div>
        </Dialog>
      </>
    )
  }
}

export default withSnackbar(ServiceBusTopicDialog)
