import { AdalConfig, adalGetToken, AuthenticationContext } from 'react-adal'

const adalConfig: AdalConfig = {
  tenant: '61d83ac2-95d8-448a-97d1-8ceb1f2d1763',
  clientId: 'fcd575b9-12d7-4796-b509-69c3666aae11',
  instance: "https://login.microsoftonline.us/",
  endpoints: {
    test: ''
  }
}

export const authContext = new AuthenticationContext(adalConfig)

export const getToken = async (resource: string) => {
  console.log(authContext, resource)
  return adalGetToken(authContext, resource)
}
