import Axios, { AxiosResponse } from 'axios'
import { createHmac } from 'crypto-browserify'

import { getToken } from 'configs/AdalConfig'

const POLICY_NAME: string = 'RootManageSharedAccessKey'

// TODO: Find another way to keep user properties with proper capitalization
const MESSAGE_PROPERTY_KEYID: string = 'KId'
const MESSAGE_PROPERTY_SIGNATURE: string = 'Signature'
const MESSAGE_PROPERTY_EVENTNAME: string = 'EventName'
const MESSAGE_PROPERTY_EVENTVERSION: string = 'EventVersion'

// Those custom props are used in CHGS
const MESSAGE_PROPERTY_TEAMID: string = 'TeamId'
const MESSAGE_PROPERTY_CARDHOLDERGROUPID: string = 'CardholderGroupId'
const MESSAGE_PROPERTY_HIGHESTMEMBERSHIPORDINAL: string = 'HighestMembershipOrdinal'

// Those custom props are used in WebHooks
const MESSAGE_PROPERTY_CALLBACKRETRYCOUNT: string = 'CallbackRetryCount'

export interface IServiceBusMessage {
  data: {}
  dataString: string
  dataFormattedString: string
  brokerProperties: IBrokerProperties
  isDeadLetter: boolean
  userProperties: Map<string, string>
  location: string
  contentType: string
  hasValidSignature: boolean
}

export interface IBrokerProperties {
  CorrelationId: string
  DeliveryCount?: number
  EnqueuedSequenceNumber?: number
  EnqueuedTimeUtc?: string
  LockToken?: string
  LockedUntilUtc?: string
  MessageId: string
  ScheduledEnqueueTimeUtc: string
  SequenceNumber?: number
  State?: string
  TimeToLive?: number
}

export class AzureServiceBusProxy {
  private _subscriptionId: string
  private _resourceGroupName: string
  private _namespaceName: string
  private _saapKey: string

  // eslint-disable-next-line
  private constructor() {}

  public static create = async (
    subscriptionId: string,
    resourceGroupName: string,
    namespaceName: string
  ): Promise<AzureServiceBusProxy> => {
    const proxy = new AzureServiceBusProxy()
    await proxy._initialize(subscriptionId, resourceGroupName, namespaceName)
    return proxy
  }

  private _initialize = async (subscriptionId: string, resourceGroupName: string, namespaceName: string) => {
    this._subscriptionId = subscriptionId
    this._resourceGroupName = resourceGroupName
    this._namespaceName = namespaceName

    this._saapKey = await this._getNamespaceSharedAccessPolicy()
  }

  private _getNamespaceSharedAccessPolicy = async (): Promise<string> => {
    const listKeys =
      'https://management.usgovcloudapi.net/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Microsoft.ServiceBus/namespaces/{namespaceName}/AuthorizationRules/{authorizationRuleName}/listKeys?api-version=2017-04-01'

    const result = await Axios.post(
      listKeys
        .replace('{subscriptionId}', this._subscriptionId)
        .replace('{resourceGroupName}', this._resourceGroupName)
        .replace('{namespaceName}', this._namespaceName)
        .replace('{authorizationRuleName}', POLICY_NAME),
      null,
      {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + (await getToken('https://management.usgovcloudapi.net')),
          'Content-Type': 'application/json'
        },
        validateStatus: () => true
      }
    )

    if (result.status === 200) {
      return result.data.primaryKey
    }
    throw new Error(
      `Unable to retrieve the Shared Access Policy for Service Bus ${this._namespaceName}(${this._resourceGroupName})\r\nNo operation can be executed further with this Service Bus.`
    )
  }

  private _generateSasToken = (resourceUri: string, signingKey: string, policyName: string, expiresInMins: number) => {
    resourceUri = encodeURIComponent(resourceUri)

    let expires = Date.now() / 1000 + expiresInMins * 60
    expires = Math.ceil(expires)
    const toSign = resourceUri + '\n' + expires

    const hmac = createHmac('sha256', new Buffer(signingKey, 'utf-8'))
    hmac.update(toSign)
    const base64UriEncoded = encodeURIComponent(hmac.digest('base64'))

    let token = 'SharedAccessSignature sr=' + resourceUri + '&sig=' + base64UriEncoded + '&se=' + expires
    if (policyName) {
      token += '&skn=' + policyName
    }

    return token
  }

  private getMessage = async (
    serviceBusNamespaceName: string,
    topicName: string,
    topicSubscriptionName: string,
    deadLetter: boolean = false
  ): Promise<AxiosResponse<string>> => {
    const resourceUrl = 'https://{namespaceName}.servicebus.usgovcloudapi.net/'.replace(
      '{namespaceName}',
      serviceBusNamespaceName
    )

    const peekMessageUrl = `${resourceUrl}{topicPath}/subscriptions/{subscriptionName}${
      deadLetter ? '/$deadletterqueue' : ''
    }/messages/head`
    const fullResourceUrl = peekMessageUrl
      .replace('{subscriptionName}', topicSubscriptionName)
      .replace('{topicPath}', topicName)

    const sasToken = this._generateSasToken(serviceBusNamespaceName, this._saapKey, POLICY_NAME, 60)

    try {
      const result = await Axios.post<string>(fullResourceUrl, null, {
        headers: {
          Authorization: sasToken
        },
        params: {
          timeout: 1
        },
        transformResponse: (res) => {
          return res
        }
      })

      return result
    } catch (e) {
      throw new Error(
        `An error was encountered trying to retrieve a message from the Service Bus Topic Subscription: ${topicSubscriptionName}.`
      )
    }
  }

  public peekLockMessage = async (
    serviceBusNamespaceName: string,
    topicName: string,
    topicSubscriptionName: string,
    deadLetter: boolean = false
  ): Promise<IServiceBusMessage> => {
    const result = await this.getMessage(serviceBusNamespaceName, topicName, topicSubscriptionName, deadLetter)

    if (result.status === 201) {
      const dataString: string = (result.data && result.data.length && result.data) || undefined
      const data: {} = dataString && JSON.parse(dataString)
      const dataFormattedString: string = data && JSON.stringify(data, null, 2)
      const brokerProperties: IBrokerProperties =
        result.headers.brokerproperties && JSON.parse(result.headers.brokerproperties)

      const userProperties = new Map<string, string>()
      if (result.headers[MESSAGE_PROPERTY_SIGNATURE.toLowerCase()]) {
        userProperties.set(MESSAGE_PROPERTY_SIGNATURE, result.headers[MESSAGE_PROPERTY_SIGNATURE.toLowerCase()])
      }
      if (result.headers[MESSAGE_PROPERTY_KEYID.toLowerCase()]) {
        userProperties.set(MESSAGE_PROPERTY_KEYID, result.headers[MESSAGE_PROPERTY_KEYID.toLowerCase()])
      }
      if (result.headers[MESSAGE_PROPERTY_EVENTNAME.toLowerCase()]) {
        userProperties.set(MESSAGE_PROPERTY_EVENTNAME, result.headers[MESSAGE_PROPERTY_EVENTNAME.toLowerCase()])
      }
      if (result.headers[MESSAGE_PROPERTY_EVENTVERSION.toLowerCase()]) {
        userProperties.set(MESSAGE_PROPERTY_EVENTVERSION, result.headers[MESSAGE_PROPERTY_EVENTVERSION.toLowerCase()])
      }

      // Custom properties are handled like that until we find a better solution to handle the casing

      if (result.headers[MESSAGE_PROPERTY_TEAMID.toLowerCase()]) {
        userProperties.set(MESSAGE_PROPERTY_TEAMID, result.headers[MESSAGE_PROPERTY_TEAMID.toLowerCase()])
      }
      if (result.headers[MESSAGE_PROPERTY_CARDHOLDERGROUPID.toLowerCase()]) {
        userProperties.set(
          MESSAGE_PROPERTY_CARDHOLDERGROUPID,
          result.headers[MESSAGE_PROPERTY_CARDHOLDERGROUPID.toLowerCase()]
        )
      }
      if (result.headers[MESSAGE_PROPERTY_HIGHESTMEMBERSHIPORDINAL.toLowerCase()]) {
        userProperties.set(
          MESSAGE_PROPERTY_HIGHESTMEMBERSHIPORDINAL,
          result.headers[MESSAGE_PROPERTY_HIGHESTMEMBERSHIPORDINAL.toLowerCase()]
        )
      }
      if (result.headers[MESSAGE_PROPERTY_CALLBACKRETRYCOUNT.toLowerCase()]) {
        userProperties.set(
          MESSAGE_PROPERTY_CALLBACKRETRYCOUNT,
          result.headers[MESSAGE_PROPERTY_CALLBACKRETRYCOUNT.toLowerCase()]
        )
      }

      // TODO: Load sign key from key vault + validate
      // let hasValidSignature = undefined
      // if (signature && keyId) {
      //   const key = ''
      //   const verify = createVerify('RSA-SHA512')
      //   hasValidSignature = verify.verify(dataString, signature, 'base64')
      // }

      const message: IServiceBusMessage = {
        data,
        dataString,
        dataFormattedString,
        brokerProperties,
        isDeadLetter: deadLetter,
        userProperties,
        location: result.headers.Location,
        hasValidSignature: undefined,
        contentType: result.headers['content-type']
      }

      return message
    } else if (result.status === 204) {
      return null
    } else {
      throw new Error(`Unexpected status code received from server: ${result.status}`)
    }
  }

  public deleteMessages = async (
    serviceBusNamespaceName: string,
    topicName: string,
    topicSubscriptionName: string,
    amount: number,
    deadLetter: boolean = false
  ): Promise<boolean> => {
    let i = 0
    while (i < amount) {
      try {
        const result = await this.getMessage(serviceBusNamespaceName, topicName, topicSubscriptionName, deadLetter)

        if (result === null) {
          return false
        } else if (result) {
          if (result.status === 201) {
            const brokerProperties: IBrokerProperties =
              result.headers.brokerproperties && JSON.parse(result.headers.brokerproperties)

            const message: IServiceBusMessage = {
              data: undefined,
              dataString: undefined,
              dataFormattedString: undefined,
              brokerProperties,
              isDeadLetter: deadLetter,
              userProperties: undefined,
              location: result.headers.Location,
              hasValidSignature: undefined,
              contentType: result.headers['content-type']
            }
            this.deleteMessage(message, serviceBusNamespaceName, topicName, topicSubscriptionName)
          } else if (result.status === 204) {
            return null
          } else {
            throw new Error(`Unexpected status code received from server: ${result.status}`)
          }
        }
      } catch (e) {
        return false
      }
      i++
    }

    return true
  }

  public peekLockMessages = async (
    serviceBusNamespaceName: string,
    topicName: string,
    topicSubscriptionName: string,
    amount: number,
    deadLetter: boolean = false,
    messageReceivedCallback: (message: IServiceBusMessage | null) => Promise<void> | void
  ): Promise<boolean> => {
    const messages: IServiceBusMessage[] = []
    let error = false
    while (messages.length < amount && !error) {
      try {
        const message = await this.peekLockMessage(
          serviceBusNamespaceName,
          topicName,
          topicSubscriptionName,
          deadLetter
        )

        if (message === null) {
          await messageReceivedCallback(null)
          return true
        } else if (message) {
          await messageReceivedCallback(message)
          messages.push(message)
        } else {
          error = true
        }
      } catch (e) {
        error = true
      }
    }

    return !error
  }

  public sendMessage = async (message: IServiceBusMessage, topicName: string): Promise<boolean> => {
    const sasToken = this._generateSasToken(this._namespaceName, this._saapKey, POLICY_NAME, 60)
    const sendMessageBatchUrl = `https://{serviceNamespace}.servicebus.usgovcloudapi.net/{topicPath}/messages`

    const userPropertiesObj = {}
    message.userProperties.forEach((value, key) => {
      userPropertiesObj[key] = value
    })

    const brokerProperties: IBrokerProperties = {
      CorrelationId: message.brokerProperties.CorrelationId,
      MessageId: message.brokerProperties.MessageId,
      ScheduledEnqueueTimeUtc: new Date().toISOString()
    }

    const result = await Axios.post(
      sendMessageBatchUrl.replace('{serviceNamespace}', this._namespaceName).replace('{topicPath}', topicName),
      message.dataString,
      {
        headers: {
          authorization: sasToken,
          'content-type': message.contentType,
          'x-ms-retrypolicy': 'NoRetry',
          brokerproperties: JSON.stringify(brokerProperties),
          ...userPropertiesObj
        }
      }
    )

    return result.status === 201
  }

  public deleteMessage = async (
    message: IServiceBusMessage,
    serviceBusNamespaceName: string,
    topicName: string,
    topicSubscriptionName: string
  ): Promise<boolean> => {
    const sasToken = this._generateSasToken(serviceBusNamespaceName, this._saapKey, POLICY_NAME, 60)

    const deleteMessageUrl = `https://{serviceNamespace}.servicebus.usgovcloudapi.net/{topicPath}/subscriptions/{subscriptionName}${
      message.isDeadLetter ? '/$deadletterqueue' : ''
    }/messages/{messageId}/{lockToken}`

    const location = deleteMessageUrl
      .replace('{serviceNamespace}', serviceBusNamespaceName)
      .replace('{topicPath}', topicName)
      .replace('{subscriptionName}', topicSubscriptionName)
      .replace('{messageId}', message.brokerProperties.MessageId)
      .replace('{lockToken}', message.brokerProperties.LockToken)

    const result = await Axios.delete(location, {
      headers: {
        Authorization: sasToken
      }
    })

    return result.status === 200
  }
}
