import { createMuiTheme, CssBaseline, MuiThemeProvider } from '@material-ui/core'
import { teal } from '@material-ui/core/colors'
import { SnackbarProvider } from 'notistack'
import React from 'react'
import { hot } from 'react-hot-loader'

import MainPage from 'components/MainPage'
import SnackbarErrorHandler from 'utils/SnackbarErrorHandler'

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: teal,
    secondary: {
      main: '#80d8ff'
    }
  },
  overrides: {
    MuiIcon: {
      root: {
        fontSize: '1.4rem' // fix for Material-Table sort arrows
      }
    }
  }
})

class App extends React.Component {
  public render() {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider>
          <SnackbarErrorHandler>
            <MainPage />
          </SnackbarErrorHandler>
        </SnackbarProvider>
      </MuiThemeProvider>
    )
  }
}

export default hot(module)(App)
