import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  createStyles,
  TextField,
  Theme,
  withStyles,
  WithStyles
} from '@material-ui/core'
import React from 'react'

import { green } from '@material-ui/core/colors'
import { AzureProxiesContext } from 'contexts/AzureProxiesContext'
import { withSnackbar, WithSnackbarProps } from 'notistack'
import { IServiceBusMessage } from 'proxies/AzureServiceBusProxy'

const styles = ({ spacing }: Theme) =>
  createStyles({
    wrapper: {
      position: 'relative'
    },
    wrapperLeftAligned: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'flex-start',
      marginTop: spacing()
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12
    }
  })

export interface IProps extends WithSnackbarProps, WithStyles<typeof styles> {
  serviceBusMessage: IServiceBusMessage
  serviceBusNamespaceName: string
  topicName: string
  topicSubscriptionName: string
}

export interface IState {
  reEnqueuing: boolean
  wasReEnqueued: boolean
}

export class ServiceBusMessageDetails extends React.Component<IProps, IState> {
  public static contextType = AzureProxiesContext
  public context!: React.ContextType<typeof AzureProxiesContext>

  constructor(props) {
    super(props)

    this.state = {
      reEnqueuing: false,
      wasReEnqueued: false
    }
  }

  private _reEnqueueDeadLetter = async () => {
    this.setState({ reEnqueuing: true })
    const sendSuccess = await this.context.sbProxy.sendMessage(this.props.serviceBusMessage, this.props.topicName)
    let wasReEnqueued = false
    if (sendSuccess) {
      const deleteSuccess = await this.context.sbProxy.deleteMessage(
        this.props.serviceBusMessage,
        this.props.serviceBusNamespaceName,
        this.props.topicName,
        this.props.topicSubscriptionName
      )
      if (deleteSuccess) {
        wasReEnqueued = true
      }
    }

    this.setState({ wasReEnqueued, reEnqueuing: false })

    if (!wasReEnqueued) {
      this.props.enqueueSnackbar(`An error was encountered trying to re-enqueue the message.`, {
        variant: 'error'
      })
    }
  }

  public render() {
    const { classes } = this.props
    return (
      <Card>
        <CardContent>
          <TextField
            multiline
            variant="outlined"
            fullWidth
            rows={10}
            value={this.props.serviceBusMessage.dataFormattedString}
          />
          <div className={classes.wrapperLeftAligned}>
            <div className={classes.wrapper}>
              <Button
                hidden={!this.props.serviceBusMessage.isDeadLetter}
                disabled={this.state.wasReEnqueued || this.state.reEnqueuing}
                variant="contained"
                color="secondary"
                onClick={this._reEnqueueDeadLetter}
              >
                Re-enqueue
              </Button>
              {this.state.reEnqueuing && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </div>
        </CardContent>
      </Card>
    )
  }
}

export default withStyles(styles)(withSnackbar(ServiceBusMessageDetails))
