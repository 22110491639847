import React from 'react'

import { AzureManagementProxy } from 'proxies/AzureManagementProxy'
import { AzureServiceBusProxy } from 'proxies/AzureServiceBusProxy'

export interface IAzureProxiesContext {
  azmProxy?: AzureManagementProxy
  sbProxy?: AzureServiceBusProxy
}

export const AzureProxiesContext = React.createContext<IAzureProxiesContext>({})
