import { Button, Dialog, DialogTitle, List, ListItem, ListItemText } from '@material-ui/core'
import React from 'react'

export interface IProps {
  peek: (amount: number, deadLetter: boolean) => Promise<void> | void
}

export interface IState {
  peeking: boolean
  peekingDeadLetter: boolean
}

export default class PeekMessagesModal extends React.Component<IProps, IState> {
  constructor(props) {
    super(props)

    this.state = {
      peeking: false,
      peekingDeadLetter: false
    }
  }

  private _handleOpenPeekMessages = (deadLetter: boolean) => async () => {
    this.setState({
      peeking: true,
      peekingDeadLetter: deadLetter
    })
  }

  private _handlePeekDialogClose = () => {
    this.setState({ peeking: false })
  }

  private _handleClick = (amount: number) => async () => {
    this._handlePeekDialogClose()
    await this.props.peek(amount, this.state.peekingDeadLetter)
  }

  public render() {
    return (
      <>
        <div style={{ marginBottom: '10px' }}>
          <Button
            onClick={this._handleOpenPeekMessages(false)}
            variant="contained"
            color="secondary"
            style={{ marginRight: '10px' }}
          >
            Peek Active Message(s)
          </Button>
          <Button
            onClick={this._handleOpenPeekMessages(true)}
            variant="contained"
            color="secondary"
            style={{ marginRight: '10px' }}
          >
            Peek Dead Letter Message(s)
          </Button>
        </div>

        <Dialog onClose={this._handlePeekDialogClose} open={this.state.peeking}>
          <DialogTitle id="simple-dialog-title">How many messages to peek?</DialogTitle>
          <div>
            <List>
              {[1, 5, 10, 25, 50, 100].map((amount) => {
                return (
                  <ListItem button onClick={this._handleClick(amount)} key={amount} style={{ textAlign: 'center' }}>
                    <ListItemText primary={amount} />
                  </ListItem>
                )
              })}
            </List>
          </div>
        </Dialog>
      </>
    )
  }
}
