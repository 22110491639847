import { CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import MaterialTable from 'material-table'
import { withSnackbar, WithSnackbarProps } from 'notistack'
import React from 'react'

import ServiceBusTopicDialog, { IProps } from 'components/ServiceBusTopicDialog'
import { AzureProxiesContext } from 'contexts/AzureProxiesContext'
import {
  AzureManagementProxy,
  IResourceGroup,
  IServiceBusNamespace,
  IServiceBusTopic,
  ISubscription
} from 'proxies/AzureManagementProxy'
import { AzureServiceBusProxy } from 'proxies/AzureServiceBusProxy'

export interface IState {
  subscriptionId: string
  resourceGroupName: string
  serviceBusNamespaceName: string
  subscriptions: ISubscription[]
  resourceGroups: IResourceGroup[]
  serviceBusNamespaces: IServiceBusNamespace[]
  serviceBusTopics: Map<string, IServiceBusTopic>
  azmProxy?: AzureManagementProxy
  sbProxy?: AzureServiceBusProxy
}

class MainPage extends React.Component<WithSnackbarProps, IState> {
  constructor(props: WithSnackbarProps) {
    super(props)

    this.state = {
      subscriptionId: '',
      resourceGroupName: '',
      serviceBusNamespaceName: '',
      subscriptions: [],
      resourceGroups: [],
      serviceBusNamespaces: [],
      serviceBusTopics: new Map<string, IServiceBusTopic>()
    }
  }

  public async componentWillMount() {
    console.log("componentWillMount")
    const azmProxy = await AzureManagementProxy.create()
    console.log("Created AZM Proxy")

    this.setState({ azmProxy })

    this._listSubscriptions()
  }

  public async componentDidUpdate(_prevProps: IProps, prevState: IState, _snapshot: any) {
    if (prevState.subscriptionId !== this.state.subscriptionId) {
      this.setState({ resourceGroupName: '', resourceGroups: [] })
      await this._listResourceGroups()
    }

    if (prevState.resourceGroupName !== this.state.resourceGroupName) {
      this.setState({ serviceBusNamespaceName: '', serviceBusNamespaces: [] })
      await this._listServiceBusNamespaces()
    }

    if (prevState.serviceBusNamespaceName !== this.state.serviceBusNamespaceName) {
      this.setState({ serviceBusTopics: new Map<string, IServiceBusTopic>() })

      if (!this.state.serviceBusNamespaceName) {
        return
      }

      try {
        const sbProxy = await AzureServiceBusProxy.create(
          this.state.subscriptionId,
          this.state.resourceGroupName,
          this.state.serviceBusNamespaceName
        )
        this.setState({ sbProxy })

        await this._listServiceBusTopics()
      } catch {
        this.props.enqueueSnackbar('Unable to establish proper connection with the selected Service Bus.', {
          variant: 'error'
        })
      }
    }
  }

  private _handleChange = (name: keyof IState) => (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const value = event.target.value
    if (this[name] === value) {
      return
    }

    this.setState((current) => ({ ...current, [name]: value }))
  }

  private _listSubscriptions = async () => {
    const subscriptions = await this.state.azmProxy.listSubscriptions()

    if (subscriptions === null) {
      this.props.enqueueSnackbar('Unable to retrieve available Subscriptions.', { variant: 'error' })
    } else if (!subscriptions || !subscriptions.length) {
      this.props.enqueueSnackbar('No Subscription could be found for the current logged in user.', {
        variant: 'warning'
      })
    } else {
      this.setState({ subscriptions })
    }
  }

  private _listResourceGroups = async () => {
    if (!this.state.subscriptionId) {
      return
    }

    const resourceGroups = await this.state.azmProxy.listResourceGroups(this.state.subscriptionId)

    if (resourceGroups === null) {
      this.props.enqueueSnackbar('Unable to retrieve available Resource Groups.', { variant: 'error' })
    } else if (!resourceGroups || !resourceGroups.length) {
      this.props.enqueueSnackbar(`No Resource Group could be found for Subscription: ${this.state.subscriptionId}.`, {
        variant: 'warning'
      })
    } else {
      this.setState({ resourceGroups })
    }
  }

  private _listServiceBusNamespaces = async () => {
    if (!this.state.resourceGroupName) {
      return
    }

    const serviceBusNamespaces = await this.state.azmProxy.listServiceBusNamespacesInResourceGroup(
      this.state.subscriptionId,
      this.state.resourceGroupName
    )

    if (serviceBusNamespaces === null) {
      this.props.enqueueSnackbar('Unable to retrieve available Service Bus Namespaces.', { variant: 'error' })
    } else if (!serviceBusNamespaces || !serviceBusNamespaces.length) {
      this.props.enqueueSnackbar(
        `No Service Bus Namespace could be found for Resource Group: ${this.state.resourceGroupName}.`,
        { variant: 'warning' }
      )
    } else {
      this.setState({ serviceBusNamespaces })
    }
  }

  private _listServiceBusTopics = async () => {
    if (!this.state.serviceBusNamespaceName) {
      return
    }

    const serviceBusTopics = await this.state.azmProxy.listServiceBusTopics(
      this.state.subscriptionId,
      this.state.resourceGroupName,
      this.state.serviceBusNamespaceName
    )

    if (serviceBusTopics === null) {
      this.props.enqueueSnackbar('Unable to retrieve available Service Bus Topics.', { variant: 'error' })
    } else if (!serviceBusTopics || !serviceBusTopics.length) {
      this.props.enqueueSnackbar(
        `No Service Bus Topics could be found for Service Bus Namespace: ${this.state.serviceBusNamespaceName}.`,
        { variant: 'warning' }
      )
    } else {
      this.setState({
        serviceBusTopics: serviceBusTopics.reduce(
          (map, obj) => map.set(obj.name, obj),
          new Map<string, IServiceBusTopic>()
        )
      })

      await this._processMessageCounts()
    }
  }

  // TODO: Make this run in the background and throttle UI Updates
  private _processMessageCounts = async () => {
    if (!this.state.serviceBusTopics || !this.state.serviceBusTopics.size) {
      return
    }

    this.state.serviceBusTopics.forEach(async (serviceBusTopic) => {
      const serviceBusTopicSubscriptions = await this.state.azmProxy.listServiceBusSubscriptionsByTopic(
        this.state.subscriptionId,
        this.state.resourceGroupName,
        this.state.serviceBusNamespaceName,
        serviceBusTopic.name
      )

      if (serviceBusTopicSubscriptions === null) {
        this.props.enqueueSnackbar('Unable to retrieve some Service Bus Topic Subscriptions.', {
          variant: 'error',
          preventDuplicate: true
        })
      } else if (!serviceBusTopicSubscriptions || !serviceBusTopicSubscriptions.length) {
        serviceBusTopic.aggregatedDeadLetterMessageCount = 0
        serviceBusTopic.aggregatedActiveMessageCount = 0
      } else {
        serviceBusTopic.aggregatedDeadLetterMessageCount = serviceBusTopicSubscriptions.reduce(
          (sum, data: any) => (sum += data.properties.countDetails.deadLetterMessageCount),
          0
        )

        serviceBusTopic.aggregatedActiveMessageCount = serviceBusTopicSubscriptions.reduce(
          (sum, data: any) => (sum += data.properties.countDetails.activeMessageCount),
          0
        )
      }

      if (serviceBusTopic.aggregatedDeadLetterMessageCount !== undefined) {
        const newState = { ...this.state }
        const newTopic = newState.serviceBusTopics.get(serviceBusTopic.name)
        newTopic.aggregatedDeadLetterMessageCount = serviceBusTopic.aggregatedDeadLetterMessageCount
        newTopic.aggregatedActiveMessageCount = serviceBusTopic.aggregatedActiveMessageCount
        newState.serviceBusTopics.set(serviceBusTopic.name, newTopic)
        this.setState({
          serviceBusTopics: newState.serviceBusTopics
        })
      }
    })
  }

  public render() {
    return (
      <main style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', width: '75%' }}>
          <div style={{ width: '100%' }}>
            <FormControl fullWidth margin="dense">
              <InputLabel>Subscription</InputLabel>
              <Select value={this.state.subscriptionId} onChange={this._handleChange('subscriptionId')}>
                {Array.from(this.state.subscriptions.values()).map((x) => (
                  <MenuItem key={x.subscriptionId} value={x.subscriptionId}>
                    {x.displayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="dense">
              <InputLabel>Resource Group</InputLabel>
              <Select value={this.state.resourceGroupName} onChange={this._handleChange('resourceGroupName')}>
                {Array.from(this.state.resourceGroups.values()).map((x) => (
                  <MenuItem key={x.id} value={x.name}>
                    {x.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="dense">
              <InputLabel>Service Bus Namespace</InputLabel>
              <Select
                value={this.state.serviceBusNamespaceName}
                onChange={this._handleChange('serviceBusNamespaceName')}
              >
                {Array.from(this.state.serviceBusNamespaces.values()).map((x: IServiceBusNamespace) => (
                  <MenuItem key={x.id} value={x.name}>
                    {x.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <AzureProxiesContext.Provider value={{ azmProxy: this.state.azmProxy, sbProxy: this.state.sbProxy }}>
            <div style={{ flexGrow: 1, flexBasis: '1px', width: '100%' }}>
              <MaterialTable<IServiceBusTopic>
                columns={[
                  { title: 'Name', field: 'name' as keyof IServiceBusTopic },
                  { title: 'Subcription Count', field: 'properties.subscriptionCount' as keyof IServiceBusTopic },
                  {
                    title: 'Dead Letter Message Count',
                    field: 'aggregatedDeadLetterMessageCount' as keyof IServiceBusTopic,
                    emptyValue: <CircularProgress size={16} disableShrink={true} />,
                    defaultSort: 'desc' as 'desc'
                  },
                  {
                    title: 'Active Message Count',
                    field: 'aggregatedActiveMessageCount' as keyof IServiceBusTopic,
                    emptyValue: <CircularProgress size={16} disableShrink={true} />
                  },
                  {
                    title: 'Actions',
                    render: (topic: IServiceBusTopic) => {
                      return topic.properties.subscriptionCount > 0 ? (
                        <ServiceBusTopicDialog
                          subscriptionId={this.state.subscriptionId}
                          resourceGroupName={this.state.resourceGroupName}
                          serviceBusNamespaceName={this.state.serviceBusNamespaceName}
                          topicName={topic.name}
                        />
                      ) : null
                    }
                  }
                ]}
                data={Array.from(this.state.serviceBusTopics.values())}
                title="Service bus topics"
                options={{ paging: false, maxBodyHeight: '600px' }}
              />
            </div>
          </AzureProxiesContext.Provider>
        </div>
      </main>
    )
  }
}

export default withSnackbar(MainPage)
